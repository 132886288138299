<template>
  <div>
    <hero></hero>
    <i-bunches></i-bunches>
    <bunches-details></bunches-details>
    <l-footer></l-footer>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>